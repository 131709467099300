@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display&display=swap');

*{
  font-family: "Red Hat Display";
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

a{
  text-decoration: none;
}



body{
  background: grey;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  margin: 0;
  box-sizing: border-box;
}

body::-webkit-scrollbar {
  display: none;
}

.category {
  display: flex;
  justify-content: center;
  
}

.categoryFlex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 30px;
  cursor: pointer;
  padding: 5px;
}

.categoryName {
  font-weight: bold;
}

.active{
  color: gold;
  scale: 0.8;
}

.activeIcon{
  scale: 0.8;
  transform: translateY(10px);
}

.swiper {
  width: 300px;
  height: 450px;
}

.swiper-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
}

.swiper-slide:nth-child(1n) {
  background-color: rgb(206, 17, 17);
}
.swiper-slide:nth-child(2n) {
  background-color: rgb(0, 140, 255);
}

.swiper-slide:nth-child(3n) {
  background-color: rgb(10, 184, 111);
}

.swiper-slide:nth-child(4n) {
  background-color: rgb(211, 122, 7);
}

.styles-module_avatar_afcc4{
  background-size: contain;
}


@media screen and (max-width: 768px){
  .swiper{
    transform: scale(0.90);
  }
}
